import React from 'react'
import styled from 'styled-components'
import { HeroImageTitle } from 'components/new/hero'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import { graphql, Link } from 'gatsby'
import YoutubePlaylist from 'components/youtube-playlist'
import Content from 'components/new/content'
import Spacer from 'components/new/spacer'
import Button from 'components/button/button'
import H2 from 'components/new/typography/h2'
import H3 from 'components/new/typography/h3'
import P from 'components/new/typography/p'
import { column } from 'styles/column'
import { clearfix } from 'styles/clearfix'
import {
  planterSetupUrl,
  amsBasicsUrl,
  operationsCenterUrl,
} from 'data/clinics/planter-clinic-2021'
import { GatsbyImage } from 'gatsby-plugin-image'

const StarfireSoftwareUpdates = props => {
  const {
    data: { displayImage, heroImage, operationsCenterImage, rowUnitImage },
    location,
  } = props
  const params = new URLSearchParams(location.search)
  const videoId = params.get('videoId')
  return (
    <Layout>
      <Helmet>
        <title>StarFire Software Updates | Planter Clinic 2021 | Hutson Inc</title>
      </Helmet>

      <Hero
        image={heroImage.childImageSharp.gatsbyImageData}
        title='StarFire Software Updates'
        overlayOpacity={0.3}
      />

      <Content kind='full'>
        <H2>How to Update Receivers with the StarFire 20-2 software</H2>
        <P>
          Learn how to update your StarFire 6000 and StarFire 3000 recievers with software version
          20-2. This update is mandatory and will allow your recievers to continue operatiing into
          the future. If this update has not been completed your recievers will not be able to
          recieve the StarFire signal and will only operate in WAAS.
        </P>
      </Content>

      <YoutubePlaylist list='PLdopwvFEiG6W4PxY054aRYgisHChcubUI' videoId={videoId} />

      <Content kind='full'>
        <H2 style={{ textAlign: 'center' }}>More Planter Clinic Courses</H2>
        <Spacer size='m' />
        <Grid>
          <Column>
            <GatsbyImage image={rowUnitImage.childImageSharp.gatsbyImageData} alt='Planter Setup' />
            <ColumnContent>
              <H3>Planter Setup</H3>
              <Spacer size='s' />
              <Button
                as={ButtonLink}
                to={planterSetupUrl}
                ghost
                color='green'
                aria-label='View the Planter Setup Course'
              >
                View course
              </Button>
            </ColumnContent>
          </Column>
          <Column>
            <GatsbyImage image={displayImage.childImageSharp.gatsbyImageData} alt='AMS Display' />
            <ColumnContent>
              <H3>AMS Basics</H3>
              <Spacer size='s' />
              <Button
                as={ButtonLink}
                to={amsBasicsUrl}
                ghost
                color='green'
                aria-label='View the AMS Basics Course'
              >
                View course
              </Button>
            </ColumnContent>
          </Column>
          <Column>
            <GatsbyImage
              image={operationsCenterImage.childImageSharp.gatsbyImageData}
              alt='Operations Center'
            />
            <ColumnContent>
              <H3>Operations Center</H3>
              <Spacer size='s' />
              <Button
                as={ButtonLink}
                to={operationsCenterUrl}
                ghost
                color='green'
                aria-label='View the Operations Center Course'
              >
                View course
              </Button>
            </ColumnContent>
          </Column>
        </Grid>
      </Content>
    </Layout>
  )
}

const Hero = styled(HeroImageTitle)`
  height: 300px;

  @media (min-width: 900px) {
    height: 450px;
  }
`

const Grid = styled.div`
  @media (min-width: 900px) {
    ${clearfix}
    display: flex;
    flex-direction: row;
  }
`

const Column = styled.div`
  background-color: #fff;
  text-align: center;

  h3 {
    text-align: center;
  }

  @media (max-width: 899px) {
    :not(:last-child) {
      margin-bottom: ${props => props.theme.size.l};
    }
  }

  @media (min-width: 900px) {
    ${column('1/3')}
    display: flex;
    flex-direction: column;

    .gatsby-image-wrapper {
      flex-grow: 2;
    }
  }
`

const ColumnContent = styled.div`
  padding: ${props => props.theme.size.m};
`

const ButtonLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
`

export const pageQuery = graphql`
  {
    heroImage: file(relativePath: { eq: "clinics/planter-clinic-2021-hero.jpg" }) {
      ...FullWidthImage
    }
    rowUnitImage: file(relativePath: { eq: "clinics/row-unit.jpg" }) {
      ...SharpImage360
    }
    displayImage: file(relativePath: { eq: "clinics/display.jpg" }) {
      ...SharpImage360
    }
    operationsCenterImage: file(relativePath: { eq: "clinics/operations-center.jpg" }) {
      ...SharpImage360
    }
  }
`

export default StarfireSoftwareUpdates
